import FilterButton from '@/common/components/FilterButton';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { formatDateToMD_or_YYYYMD } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import { MenuItem } from '@chakra-ui/react';
import { FC, lazy, useMemo } from 'react';
const LocalizedDatePicker = lazy(() => import('@/common/components/LocalizedDatePicker'));

export type DateType = [Date?, Date?];
type WorkOrderDatePickerButtonProps = {
  dates: DateType;
  onChange: (value: DateType) => void;
  onReset: () => void;
  defaultLabel: string;
};

const WorkOrderDatePickerButton: FC<WorkOrderDatePickerButtonProps> = (
  props: WorkOrderDatePickerButtonProps
) => {
  const { dates, onReset, onChange, defaultLabel } = props;
  const [startDate, endDate] = dates;

  const { i18n } = useTranslation();

  const isDatePicked = useMemo(() => {
    return Boolean(startDate && endDate);
  }, [startDate, endDate]);

  const dateLabel = useMemo(() => {
    if (isDatePicked && endDate !== null) {
      if (startDate?.getTime() === endDate?.getTime()) {
        return formatDateToMD_or_YYYYMD(startDate);
      }
      return `${formatDateToMD_or_YYYYMD(startDate)}-${formatDateToMD_or_YYYYMD(endDate)}`;
    }
    return defaultLabel;
  }, [defaultLabel, endDate, isDatePicked, startDate]);

  return (
    <FilterButton
      label={dateLabel}
      isActive={isDatePicked}
      resetFilter={() => onReset()}
      menuListProps={{
        p: 0,
        m: 0,
        bg: 'transparent',
        boxShadow: 'none',
        border: '0px',
      }}
    >
      <MenuItem
        as='div'
        display='flex'
        alignItems='stretch'
        bg='transparent'
        _hover={{
          bg: 'transparent',
        }}
        p={0}
        m={0}
      >
        <SuspenseWithSpinner>
          <LocalizedDatePicker
            selected={startDate}
            onChange={(dates) => onChange(dates as [Date?, Date?])}
            startDate={startDate}
            endDate={endDate}
            locale={i18n.language}
            selectsRange
            inline
          />
        </SuspenseWithSpinner>
      </MenuItem>
    </FilterButton>
  );
};

export default WorkOrderDatePickerButton;
